<template>
  <div>
    <div class="site-navbar">
      <b-navbar toggleable="lg" type="dark" variant="info">
        <div class="container justify-content-between">
          <b-navbar-brand href="#">
            <img src="../assets/img/logo.png" width="156" height="32" title="诚派科技" alt="诚派科技">
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item>首页</b-nav-item>
              <b-nav-item>关于我们</b-nav-item>
              <b-nav-item>定制化开发</b-nav-item>
              <b-nav-item>产品标准化</b-nav-item>
              <b-nav-item>成功案例</b-nav-item>
              <b-nav-item>最新咨询</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </div>  
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  
</style>
